function parseCardRelationInstantClickOverlay() {
    $('.bloc-relation-instants .card:not(.parsed)').on('click', function (e) {
            if (!isMobileOrSmaller()) {
                e.preventDefault();
                var elem = $(this);

                var sit_url = elem.attr('data-href');
                var overlay_id = 'overlay-instant';

                $('#' + overlay_id).addClass("overlay-instant");
                $('#' + overlay_id).find(".single-instant").addClass("single-instant");

                $('#' + overlay_id + ' .js-overlay-instant').html('');
                $('#' + overlay_id + ' .loader').removeClass('hide');

                $.get(`${sit_url}?overlay`, function (responseHTML) {
                    $('#' + overlay_id + ' .loader').addClass('hide');
                    $('#' + overlay_id + ' .js-overlay-instant').html(responseHTML);
                });

                window.history.pushState({}, '', sit_url);

                $('body').on('click', '.btn-close[data-overlay-close=overlay-instant]', function (e) {
                    url = new URL(sit_url);
                    path = url.pathname.split("/");
                    path.pop();
                    path.pop();
                    url.pathname = path.join("/")
                    console.log(url)

                    window.history.pushState({}, '', url);
                });

                th_overlay.open(overlay_id, true, true, false);
            }
        }
    ).addClass('parsed');


    $('.bloc-relation-instants .card.card-instant a').on('click', function (e) {
        e.stopPropagation();
    });
}

parseCardRelationInstantClickOverlay();

